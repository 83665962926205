import React from 'react';
import Layout from "../components/layout";

export default function Disclaimer () {
  return (
    <Layout>

<div className="relative py-16 bg-white overflow-hidden">
  <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
    <div className="relative h-full text-lg max-w-prose mx-auto">
      <svg className="absolute top-12 left-full transform translate-x-32" width="404" height="384" fill="none" viewBox="0 0 404 384">
        <defs>
          <pattern id="74b3fd99-0a6f-4271-bef2-e80eeafdf357" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
            <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
          </pattern>
        </defs>
        <rect width="404" height="384" fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)" />
      </svg>
      <svg className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32" width="404" height="384" fill="none" viewBox="0 0 404 384">
        <defs>
          <pattern id="f210dbf6-a58d-4871-961e-36d5016a0f49" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
            <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
          </pattern>
        </defs>
        <rect width="404" height="384" fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)" />
      </svg>
      <svg className="absolute bottom-12 left-full transform translate-x-32" width="404" height="384" fill="none" viewBox="0 0 404 384">
        <defs>
          <pattern id="d3eb07ae-5182-43e6-857d-35c643af9034" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
            <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
          </pattern>
        </defs>
        <rect width="404" height="384" fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)" />
      </svg>
    </div>
  </div>
  <div className="relative px-4 sm:px-6 lg:px-8">
    <div className="text-lg max-w-prose mx-auto mb-6">
      <p className="text-base text-center leading-6 text-indigo-600 font-semibold tracking-wide uppercase">Tokensack</p>
      <h1 className="mt-2 mb-8 text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">Disclaimer</h1>
    </div>
    <div className="prose prose-lg text-gray-500 mx-auto">


Thank you for visiting Tokensacks.com
<br/><br/>

This disclaimer covers the information provided on our website and the use of that information.

<br/><br/>

<h2>No Investment Advice</h2>
The information provided on this website does not constitute investment advice, financial advice, trading advice, or any other sort of advice and you should not treat any of the website's content as such. Tokensack does not recommend that any cryptocurrency should be bought, sold, or held by you. Do conduct your own due diligence and consult your financial advisor before making any investment decisions.
<br/><br/>
<h2>Non Endorsement</h2>
The appearance of third party advertisements and hyperlinks on Tokensack does not constitute an endorsement, guarantee, warranty, or recommendation by Tokensack. Do conduct your own due diligence before deciding to use any third party services.
<br/><br/>
<h2>Accuracy of Information</h2>
Tokensack will strive to ensure accuracy of information listed on this website although it will not hold any responsibility for any missing or wrong information. Tokensack provides all information as is. You understand that you are using any and all information available here at your own risk.
<br/><br/>
<h2>Affiliate Disclosure</h2>
Tokensack may receive compensation for affiliate links. This compensation may be in the form of money or services and could exist without any action from a site visitor. Should you perform activities in relation to an affiliate link, it is understood that some form of compensation might be made to Tokensack. For example, if you click on an affiliate link, and sign up and trade on an exchange, Tokensack may receive compensation. Each affiliate link is clearly indicated by an information above it.
<br/><br/>
</div>
</div>
</div>



    </Layout>
  )
}
